import { Box, Text } from "@chakra-ui/react";
import Navbar from "../../Components/Navbar";
import checkImage from "../../assets/images/check.png";

const SignupSuccess = () => {
  return (
    <>
      <Navbar page={"success"} />
      <Box marginTop={"7rem"}>
        <Box display={"flex"} justifyContent={"center"}>
          <img src={checkImage} alt="success image" width={"25%"} />
        </Box>
        <Text
          textAlign={"center"}
          fontFamily={`'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande',
                        'Lucida Sans Unicode', Geneva, Verdana, sans-serif`}
          lineHeight={"2.5rem"}
          fontSize={"2rem"}
          marginTop={'2rem'}
        >
          Congratulations on signing up! <br />
          Please sign in using the extension to get started.
        </Text>
      </Box>
    </>
  );
};

export default SignupSuccess;
