import {
  HStack,
  Text,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import { motion } from "framer-motion";

type Props = {};
const MotionVStack = motion(VStack);

const MultiMarketContent = (props: Props) => {
  const titleFontSize = useBreakpointValue({
    base: "2rem",
    md: "3rem",
    lg: "3rem",
    xl: "5rem",
  });
  const subtitleFontSize = useBreakpointValue({
    base: "1.5rem",
    md: "2rem",
    lg: "2rem",
    xl: "2rem",
  });
  const containerWidth = useBreakpointValue({
    base: "100vw",
    sm: "90vw",
    md: "80vw",
    lg: "70vw",
    xl: "70vw",
  });

  return (
    <HStack
      w={{ base: "100%" }} // Full width for mobile
      justify={"center"}
      paddingTop={"3rem"}
      paddingBottom={"4rem"}
      position={"relative"}
    >
      <MotionVStack
        w={containerWidth} // Makes the width responsive
        position={"relative"}
        justify={"center"}
        spacing={"2rem"}
        animate={{ scale: 0.8, opacity: 0.8 }}
        transition={{ duration: 0.5 }}
        whileInView={{ scale: 1, opacity: 1 }}
      >
        <Text
          fontStyle={"normal"}
          fontWeight={700}
          fontSize={titleFontSize} // Adjust font size based on screen size
          lineHeight={"3.6rem"}
          display={"flex"}
          textAlign={"center"}
          alignItems={"center"}
          color={"#393939"}
        >
          Multi-Marketplace Support
        </Text>
        <Text
          color={"gray.500"}
          fontWeight={600}
          fontSize={subtitleFontSize} // Adjust font size based on screen size
          w={"100%"}
          paddingX={"2rem"}
          textAlign={"center"}
        >
          Seamlessly integrates with eBay, Upright, and other major marketplaces
          for a streamlined listing process.
        </Text>
      </MotionVStack>
    </HStack>
  );
};

export default MultiMarketContent;
