import { Box, Grid, Stack, Text, VStack } from "@chakra-ui/react";

const Testimonials = ({TestimonialsRef}:any) => {
  return (
    <VStack ref={TestimonialsRef} w={{base:"100%",md:"70vw"}} margin={"auto"} paddingX={{base:"2rem",lg:"5rem"}} paddingY={"4rem"} position={'relative'}>
      <Text fontSize={"2rem"} letterSpacing={"0.15rem"}>
        Testimonials
      </Text>
      <Grid gridTemplateColumns={{base:"repeat(1,1fr)",lg:"repeat(2,1fr)"}} justifyContent={'center'} padding={{base:"0",md:"2rem"}} gap={"2rem"}>
        <Box position={"relative"}>
          <Stack
            zIndex={"10"}
            minH={"200px"}
            rounded={"md"}
            border={"1px solid transparent"}
            boxShadow={"rgba(0, 0, 0, 0.24) 0px 3px 8px"}
            padding={"2rem"}
            bgColor={"white"}
            _hover={{ borderImage: "linear-gradient(45deg, yellow,#ffd400) 1" }}
          >
            <Text
              fontFamily={"system-ui"}
              fontSize={"2.5rem"}
              lineHeight={0}
              textAlign={"start"}
              color={"#7300f2"}
            >
              “
            </Text>
            <Stack>
              <Text textColor={"gray"} fontSize={"1.4rem"}>
                “Infinity AI has completely transformed how I list items! My
                sales have increased significantly!”
              </Text>
              <Text
                fontSize={"1.2rem"}
                letterSpacing={"0.15rem"}
                fontFamily={"system-ui"}
                m={0}
              >
                Alex T.
              </Text>
              <Text m={0} mt={"-10px"} fontWeight={400} color={'gray.500'}>Thrift Store Owner</Text>
            </Stack>
          </Stack>
        </Box>
        <Box position={"relative"}>
          <Stack
            minH={"200px"}
            rounded={"md"}
            border={"1px solid transparent"}
            boxShadow={"rgba(0, 0, 0, 0.24) 0px 3px 8px"}
            padding={"2rem"}
            bgColor={"white"}
            _hover={{ borderImage: "linear-gradient(45deg, yellow,#ffd400) 1" }}
          >
            <Text
              fontFamily={"system-ui"}
              fontSize={"2.5rem"}
              lineHeight={0}
              textAlign={"start"}
              color={"#7300f2"}
            >
              “
            </Text>
            <Stack>
              <Text textColor={"gray"} fontSize={"1.4rem"}>
                “I can’t believe how much time I’ve saved. It’s a game-changer!”
              </Text>
              <Text
                fontSize={"1.2rem"}
                letterSpacing={"0.15rem"}
                fontFamily={"system-ui"}
              >
                Sarah Brown
              </Text>
              <Text m={0} mt={"-10px"} fontWeight={400} color={'gray.500'}>Retail Manager</Text>
            </Stack>
          </Stack>
        </Box>
      </Grid>
    </VStack>
  );
};

export default Testimonials;
