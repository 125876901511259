import { Box, Button, HStack, Icon, Text, VStack } from "@chakra-ui/react";
import { RxHamburgerMenu } from "react-icons/rx";
import LottieAnimation from "../../../../../Components/LottieAnimation";
import robotGreen from "../../../../../assets/animations/robotgreen.json";
import HeroSectionStyle from "../index.module.css";
import { motion } from "framer-motion";

const MotionVStack = motion(VStack);

function Infinitydashboard({bookdemoOpen}:any) {
  return (
    <MotionVStack
      w={{ base: "100%" }} // Full width on mobile, 40vw on larger screens
      align={{base:"center",xl:"flex-end"}}
      initial={{ opacity: 0, x: 200 }} // Start off-screen to the right with zero opacity
      animate={{ opacity: 1, x: 0 }} // Fade in and move to the original position
      transition={{ duration: 0.8, ease: "easeOut" }}
    >
      <Box
        position={"relative"}
        w={{ base: "80%"}} // Full width on mobile, 20vw on larger screens
        border={"1px solid"}
        borderColor={"gray.400"}
        boxShadow={
          "#00000066 5px 5px, #0000004d 10px 10px, #00000033 15px 15px, #0000001a 20px 20px, #0000000d 25px 25px"
        }
        rounded={"md"}
        paddingBottom={"1rem"}
      >
        <HStack
          justify={"space-between"}
          bgColor={"#ffd400"}
          pb={2}
          roundedTop={"md"}
        >
          <Box px={4} py={2} borderTopRadius="lg" cursor={"pointer"}>
            <Text
              align={"center"}
              fontSize={{ base: "1.5rem", md: "2rem" }} // Adjust font size for mobile
              fontWeight={700}
              p={0}
              m={0}
            >
              Infinity AI
            </Text>
            <Text
              lineHeight={"0.2rem"}
              fontSize={{ base: "1rem", md: "1.3rem" }} // Adjust font size for mobile
              pb={"1rem"}
              align={"right"}
              p={0}
              m={0}
            >
              by Hammoq
            </Text>
          </Box>
          <Box>
            <Icon as={RxHamburgerMenu} boxSize={12} cursor={"pointer"} pr={2} />
          </Box>
        </HStack>
        <Box padding={"0.5rem 1rem"}>
          <HStack
            fontSize={{ base: "1rem", md: "1.2rem" }} // Adjust font size for mobile
            w={"100%"}
            p={"0.3rem 0.5rem"}
            justify={"center"}
            border={"2px solid #ffd400"}
            rounded={"md"}
          >
            <HStack>
              <Text>
                Available{" "}
                <Text as={"span"} fontWeight={600}>
                  free
                </Text>{" "}
                Credits:
              </Text>
              <Text fontWeight={600}>100</Text>
            </HStack>
          </HStack>
          <VStack
            minHeight={"60%"}
            width={"100%"}
            mt={"1rem"}
            paddingY={"1rem"}
          >
            <LottieAnimation
              animationData={robotGreen}
              style={{ width: "80%" }}
            />
          </VStack>
          <HStack justify={"center"} w={"100%"} mt={"1rem"}>
            <Button
              bgColor={"#ffd400"}
              _hover={{ bg: "#e6c200" }}
              _active={{ bg: "#ccaa00" }}
              className={HeroSectionStyle.blinking}
              w={{ base: "100%", md: "80%" }} // Full width on mobile, 80% on larger screens
              rounded={"full"}
              onClick={()=>{
                // bookdemoOpen()
                window.location.href="https://chromewebstore.google.com/detail/infinity-ai-by-hammoq/bemfglnkeaeomabmdoopjpbcbacpnfne"
              }}
            >
              Try it Now
            </Button>
          </HStack>
        </Box>
      </Box>
    </MotionVStack>
  );
}

export default Infinitydashboard;
